<template>
  <div
    v-if="vetProfile"
    class="w-full lg:w-[960px] flex flex-col sm:flex-row justify-between items-center sm:items-start px-4 py-2 bg-white rounded-lg cursor-pointer"
  >
    <div
      class="flex flex-col sm:flex-row items-center sm:items-start gap-4 w-full"
    >
      <MiscFadeInImage
        v-if="vetProfile.imageUrl"
        :src="runtimeConfig.public.imageBaseUrl + '/' + vetProfile.imageUrl"
        custom-class="w-14 h-14"
        rounding="rounded-full"
      />
      <div class="text-center sm:text-left">
        <div class="text-neutral-900 text-base font-medium">
          {{ vetProfile.firstName }} {{ vetProfile.lastName }}
        </div>
        <div class="text-slate-500 text-sm">{{ truncatedAbout }}</div>
      </div>
    </div>
    <button
      class="mt-4 ml-2 sm:ml-0 sm:mt-1 w-full sm:w-auto bg-gray-100 rounded-xl px-4 py-2 text-neutral-900 text-sm font-medium whitespace-nowrap hover:bg-gray-200"
      @click="onBtnGoToVetProfile(vetProfile)"
    >
      {{ ctaText }}
    </button>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useRouter } from 'vue-router'

const runtimeConfig = useRuntimeConfig()
const router = useRouter()

const props = defineProps({
  vetProfile: {
    type: Object,
    required: true,
  },
  ctaText: {
    type: String,
    default: 'Book now',
  },
  truncateLength: {
    type: Number,
    default: null,
  },
})

const truncatedAbout = computed(() => {
  if (
    props.truncateLength &&
    props.vetProfile.about &&
    props.vetProfile.about.length > props.truncateLength
  ) {
    return `${props.vetProfile.about.slice(0, props.truncateLength)}...`
  }
  return props.vetProfile.about
})

const onBtnGoToVetProfile = (vetProfile) => {
  router.push(`/veterinarians/${vetProfile.id}/book-meeting`)
}
</script>
